import $ from 'jquery';

$(document).ready(function(){
    Hidden();
});
function Hidden(){
    $("#popup2").hide();
};

 $('.cost_info_btn').click( function PopUpShow(){
    $("#popup2").show();
})
$('.close_btn').click( function PopUpHide(){
    $("#popup2").hide();
});
