import $ from 'jquery';

$(document).ready(function(){
    Hidden();
});
function Hidden(){
    $("#popup1").hide();
};

 $('.right_btn_info').click( function PopUpShow(){
    $("#popup1").show();
})
$('.close_btn').click( function PopUpHide(){
    $("#popup1").hide();
});
