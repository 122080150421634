var menuElem = document.getElementById('sweeties');
var titleElem = menuElem.querySelector('.title');

var menuElem2 = document.getElementById('sweeties2');
var titleElem2 = menuElem2.querySelector('.title2');

var menuElem3 = document.getElementById('sweeties3');
var titleElem3 = menuElem3.querySelector('.title3');

var menuElem4 = document.getElementById('sweeties4');
var titleElem4 = menuElem4.querySelector('.title4');

var menuElem5 = document.getElementById('sweeties5');
var titleElem5 = menuElem5.querySelector('.title5');

var menuElem6 = document.getElementById('sweeties6');
var titleElem6 = menuElem6.querySelector('.title6');

var menuElem7 = document.getElementById('sweeties7');
var titleElem7 = menuElem7.querySelector('.title7');

var menuElem8 = document.getElementById('sweeties8');
var titleElem8 = menuElem8.querySelector('.title8');

titleElem.onclick = function() {
  menuElem.classList.toggle('open');
};

titleElem2.onclick = function() {
  menuElem2.classList.toggle('open');
};

titleElem3.onclick = function() {
  menuElem3.classList.toggle('open');
};

titleElem4.onclick = function() {
  menuElem4.classList.toggle('open');
};
titleElem5.onclick = function() {
  menuElem5.classList.toggle('open');
};

titleElem6.onclick = function() {
  menuElem6.classList.toggle('open');
};

titleElem7.onclick = function() {
  menuElem7.classList.toggle('open');
};

titleElem8.onclick = function() {
  menuElem8.classList.toggle('open');
};

import $ from "jquery";

 $(document).ready(function(){
    $('.collapse_item').click(function () {
        $(this).find('.arrow_img').toggleClass('arrow_img_down');
      });
    $('.collapse_item').click(function() {
        $(this).toggleClass('active2');
    });

})
 
