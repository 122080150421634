import $ from 'jquery';

var wrap_tel = $('#wrapper_tel'),
     btn_tel = $('.open-modal-btn_tel'),
     modal_tel = $('.cover_tel, .modal_tel, .content_tel');

btn_tel.on('click', function() {
  modal_tel.fadeIn();
});

// close modal
$('.modal_tel').click(function() {
  wrap_tel.on('click', function(event) {
    var select_tel = $('.content_tel');
    if ($(event.target).closest(select_tel).length)
      return;
    modal_tel.fadeOut();
    wrap_tel.unbind('click');
  });
});

var wrap_tend = $('#wrapper_tend'),
     btn_tend = $('.open-modal-btn_tend'),
     modal_tend = $('.cover_tend, .modal_tend, .content_tend');

btn_tend.on('click', function() {
  modal_tend.fadeIn();
});

// close modal
$('.modal_tend').click(function() {
  wrap_tend.on('click', function(event) {
    var select_tend = $('.content_tend');
    if ($(event.target).closest(select_tend).length)
      return;
    modal_tend.fadeOut();
    wrap_tend.unbind('click');
  });
});







