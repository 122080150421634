var menuElem = document.getElementById('sweeties');
var titleElem = menuElem.querySelector('.title');

var menuElem2 = document.getElementById('sweeties2');
var titleElem2 = menuElem2.querySelector('.title2');

var menuElem3 = document.getElementById('sweeties3');
var titleElem3 = menuElem3.querySelector('.title3');

var menuElem4 = document.getElementById('sweeties4');
var titleElem4 = menuElem4.querySelector('.title4');

var menuElem5 = document.getElementById('sweeties5');
var titleElem5 = menuElem5.querySelector('.title5');

titleElem.onclick = function() {
  menuElem.classList.toggle('open');
};

titleElem2.onclick = function() {
  menuElem2.classList.toggle('open');
};

titleElem3.onclick = function() {
  menuElem3.classList.toggle('open');
};

titleElem4.onclick = function() {
  menuElem4.classList.toggle('open');
};
titleElem5.onclick = function() {
  menuElem5.classList.toggle('open');
};