import "%modules%/header/header";
import "%modules%/main/tabs_block2/tabs_block2";
import "%modules%/header/form";
import "%modules%/header_main/form_tend";
import "%modules%/forms/form_1";
import "%modules%/forms/form_2";
import "%modules%/forms/form_vacan";
import "%modules%/footer/footer";
import "%modules%/med/info_wrap_1/info_block1";
import "%modules%/med/info_wrap_5/info_block5";
import "%modules%/bank/info_wrap_1/info_block1";
import "%modules%/archive/info_wrap_1/info_block1";
import "%modules%/office/info_wrap_1/info_block1";
import "%modules%/present/info_wrap_1/info_block1";
import "%modules%/contacts/info_wrap_1/info_block1";
import "%modules%/gov/info_wrap_1/info_block1";
import "%modules%/present/info_wrap_6/info_block6";
import "%modules%/office/info_wrap_2/info_block2";
import "%modules%/office/info_wrap_2/info_block2";
import "%modules%/partners/info_wrap_3/info_block3";





